<script setup>
import { cdnImageUrl } from '@dpgradio/creative'
import { useVotesStore } from '../stores/votes'
import { useAudioPlayersStore } from '../stores/audioPlayers'
import VoteButton from './VoteButton.vue'
import AudioWaves from './AudioWaves.vue'

const votesStore = useVotesStore()
const audioPlayersStore = useAudioPlayersStore()
audioPlayersStore.initialize()
</script>

<template>
  <div class="grid grid-cols-2 md:grid-cols-5 gap-4 md:gap-8 group/tracks">
    <button
      v-for="track in votesStore.tracks"
      :key="track.id"
      class="group/track hover:scale-110 transition-transform duration-300 relative z-0 hover:z-10 text-left"
      @mouseenter="audioPlayersStore.playTrack(track.id)"
      @mouseleave="audioPlayersStore.stopTrack(track.id)"
      @touchstart="audioPlayersStore.playTrack(track.id)"
      @touchend="audioPlayersStore.stopTrack(track.id)"
    >
      <div class="h-full relative z-10 group-hover/track:z-30">
        <div
          class="h-full rounded-md overflow-hidden shadow-md group-hover/track:animate-[bounce-easy_1s_infinite] group-hover/tracks:blur-sm group-hover/track:!blur-none"
        >
          <div class="h-full aspect-square hover:animate-[zoom_15s_infinite] bg-gray-300">
            <img v-if="track.thumbnail" :src="cdnImageUrl(track.thumbnail)" class="w-full h-full object-cover" />
          </div>
          <div class="absolute z-30 pt-2 pr-4 top-0 right-0 opacity-0 group-hover/track:opacity-100">
            <AudioWaves class="w-8 h-8" />
          </div>
          <div class="absolute z-30 bottom-0 left-0 right-0">
            <VoteButton :trackId="track.id" />
          </div>
        </div>
      </div>
      <div
        class="absolute group-hover/track:z-20 left-0 right-0 -translate-y-36 group-hover/track:translate-y-0 transition-transform duration-300 ease-out"
      >
        <div class="bg-white bg-opacity-95 rounded-b-2xl border-primary shadow-xl pt-8 -mt-8">
          <div class="p-4 pt-3 leading-tight">
            <p class="text-primary font-semibold">{{ track.title }}</p>
            <p class="text-gray-500 text-sm">{{ track.artist.name }}</p>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<style>
@keyframes bounce-easy {
  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(-2%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>
