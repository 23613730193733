import { tap } from '@dpgradio/creative'
import { defineStore } from 'pinia'
import { useVotesStore } from './votes'
import { watch } from 'vue'

export const useAudioPlayersStore = defineStore('audioPlayers', {
  state: () => ({
    audioPlayers: {},
  }),
  actions: {
    initialize() {
      const votesStore = useVotesStore()
      watch(
        () => votesStore.tracks,
        () => {
          votesStore.tracks && this.createAudioPlayers(votesStore.tracks)
        },
        { immediate: true }
      )
    },
    createAudioPlayers(tracks) {
      tracks
        .filter((track) => track.hooks?.m4a)
        .forEach((track) => {
          this.audioPlayers[track.id] = tap(new Audio(track.hooks.m4a), (player) => {
            player.loop = true
          })
        })
    },
    playTrack(id) {
      this.audioPlayers[id].play()
    },
    stopTrack(id) {
      this.audioPlayers[id].pause()
      this.audioPlayers[id].currentTime = 0
    },
    stopAll() {
      Object.values(this.audioPlayers).forEach((player) => {
        player.pause()
        player.currentTime = 0
      })
    },
  },
})
