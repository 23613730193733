<script setup>
import { cdnImageUrl } from '@dpgradio/creative'
import { useVotesStore } from '../stores/votes'
import ShareButtons from './ShareButtons.vue'

const votesStore = useVotesStore()
</script>

<template>
  <transition name="fade">
    <div
      v-if="votesStore.winner"
      :class="{ absolute: votesStore.tracks }"
      class="z-50 w-full h-full backdrop-blur-md flex items-center justify-center px-4"
    >
      <div class="bg-white max-w-lg rounded-3xl p-8">
        <h1 class="mb-4 font-title text-primary text-2xl font-semibold">Je stem is binnen!</h1>
        <div class="mb-8 bg-gray-100 rounded-2xl overflow-hidden shadow-xl flex items-center">
          <div class="w-32 h-32 bg-gray-200">
            <img :src="cdnImageUrl(votesStore.winner.thumbnail)" class="w-full h-full object-cover" />
          </div>
          <div class="flex-1 p-4 md:p-6 space-y-1">
            <p class="md:text-lg text-primary font-semibold leading-tight">{{ votesStore.winner.title }}</p>
            <p class="text-gray-500 text-sm md:text-base">{{ votesStore.winner.artist.name }}</p>
          </div>
        </div>
        <p class="mb-6 text-gray-700 text-center">Share jouw nummer 1 met de rest van de wereld</p>
        <div class="text-center space-x-4">
          <ShareButtons />
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
