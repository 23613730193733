<script setup>
import { ref } from 'vue'
import Spinner from '../assets/icons/spinner.svg'
import PrimaryButton from './PrimaryButton.vue'

const props = defineProps({
  share: { type: Function, required: true },
})

const loading = ref(false)

const shareWithState = async () => {
  loading.value = true
  await props.share()
  loading.value = false
}
</script>

<template>
  <PrimaryButton :disabled="loading" class="px-6" @click="shareWithState">
    <Spinner v-if="loading" class="h-6" />
    <slot v-else />
  </PrimaryButton>
</template>
