<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 38.05" fill="white">
    <path id="freq1" d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z" />
    <path id="freq2" d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z" />
    <path id="freq3" d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z" />
    <path id="freq4" d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z" />
    <path id="freq5" d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z" />
    <path id="freq6" d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z" />
    <path id="freq7" d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z" />
    <path id="freq8" d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z" />
    <path id="freq9" d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z" />
  </svg>
</template>

<style>
#freq1 {
  animation: pulse 1s infinite;
  animation-delay: 0.15s;
}

#freq2 {
  animation: pulse 1s infinite;
  animation-delay: 0.3s;
}

#freq3 {
  animation: pulse 1s infinite;
  animation-delay: 0.45s;
}

#freq4 {
  animation: pulse 1s infinite;
  animation-delay: 0.6s;
}

#freq5 {
  animation: pulse 1s infinite;
  animation-delay: 0.75s;
}

#freq6 {
  animation: pulse 1s infinite;
  animation-delay: 0.9s;
}

#freq7 {
  animation: pulse 1s infinite;
  animation-delay: 1.05s;
}

#freq8 {
  animation: pulse 1s infinite;
  animation-delay: 1.2s;
}

#freq9 {
  animation: pulse 1s infinite;
  animation-delay: 1.35s;
}

@keyframes pulse {
  0%,
  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
  50% {
    transform: scaleY(0.75);
    transform-origin: 50% 50%;
  }
}
</style>
