<script setup>
import { ref } from 'vue'
import { useVotesStore } from '../stores/votes'
import PrimaryButton from './PrimaryButton.vue'

const votesStore = useVotesStore()

const visible = ref(true)
</script>

<template>
  <transition name="fade">
    <div
      v-if="visible"
      :class="{ absolute: votesStore.tracks }"
      class="z-50 w-full h-full backdrop-blur-md flex items-center justify-center px-4"
    >
      <div class="bg-white max-w-md rounded-3xl p-8">
        <h1 class="mb-2 font-title text-primary text-2xl font-semibold">
          Stem op de top-{{ votesStore.size || '...' }}!
        </h1>
        <p class="mb-6 text-gray-700">
          Kies jouw échte nummer van 1 de lijst. Beweeg zometeen met je muis over de tracks om een stukje te horen. Je
          favoriet gevonden? Klik op stem om het nummer te kiezen als jouw winnaar.
        </p>
        <p v-if="!votesStore.tracks" class="text-center text-gray-400">Laden...</p>
        <PrimaryButton v-else class="block mx-auto" @click="visible = false">Ik heb het begrepen!</PrimaryButton>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
