<script setup>
import { useVotesStore } from '../stores/votes'
import { useAudioPlayersStore } from '../stores/audioPlayers'
import PrimaryButton from './PrimaryButton.vue'
import Spinner from '../assets/icons/spinner.svg'
import { ref } from 'vue'

const props = defineProps({
  trackId: Number,
})

const votesStore = useVotesStore()
const audioPlayersStore = useAudioPlayersStore()

const state = ref('idle')

const vote = async () => {
  state.value = 'loading'
  try {
    await votesStore.vote(props.trackId)
    audioPlayersStore.stopAll()
    state.value = 'success'
  } catch (error) {
    console.error(error)
    state.value = 'error'
  } finally {
    setTimeout(() => (state.value = 'idle'), 3000)
  }
}
</script>

<template>
  <PrimaryButton
    class="block mx-auto rounded-t-3xl rounded-b-none shadow-none"
    :disabled="state != 'idle'"
    @click="vote"
  >
    <span v-if="state === 'idle'">Stem</span>
    <span v-if="state === 'error'">Error</span>
    <Spinner v-if="state === 'loading' || state === 'success'" class="h-7" />
  </PrimaryButton>
</template>
