<script setup>
import { cdnImageUrl, cdnUrl, config } from '@dpgradio/creative'
import { Shareable, ImageGeneratorProperties } from '@dpgradio/creative/share'
import { useVotesStore } from '../stores/votes'
import ShareButton from './ShareButton.vue'
import FacebookIcon from '../assets/icons/facebook.svg'
import InstagramIcon from '../assets/icons/instagram.svg'
import WhatsappIcon from '../assets/icons/whatsapp.svg'

const votesStore = useVotesStore()

const shareUrl =
  window.location.href.includes('localhost') || window.location.href.includes('pages.dev')
    ? 'https://joe.be/stem-hier-op-jouw-favoriet-in-de-top-10'
    : window.location.href

const shareable = new Shareable()
  .withTitle(votesStore.socialMetadata.social_title)
  .withDescription(votesStore.socialMetadata.social_description)
  .redirectTo(shareUrl)
  .fromDomain(config('domain'))

const createImageProperties = (width, height, platform) =>
  new ImageGeneratorProperties(`https://static.qmusic.be/top-n-voting/social-share/index.html`)
    .withDimensions(width, height)
    .withPayload({
      type: platform,
      track: {
        title: votesStore.winner.title,
        artist: votesStore.winner.artist.name,
        artwork: cdnImageUrl(votesStore.winner.thumbnail, 'w800'),
      },
      backgroundImage: cdnUrl(votesStore.socialMetadata[`${platform}_cover_url`]),
    })

const shareOnFacebook = async () => {
  const image = createImageProperties(1200, 630, 'facebook')

  ;(await shareable.generateUsingImage(image)).openFacebookUrl()
}

const shareOnInstagram = async () => {
  const image = createImageProperties(1080, 1920, 'instagram')

  ;(await shareable.generateUsingImage(image)).openInstagramUrl()
}

const shareOnWhatsapp = async () => {
  const image = createImageProperties(1200, 630, 'facebook')

  ;(await shareable.generateUsingImage(image)).openWhatsappUrl()
}

const available = (platform) => votesStore.socialMetadata[`${platform}_cover_url`]
</script>

<template>
  <ShareButton v-if="available('facebook')" :share="shareOnFacebook">
    <FacebookIcon class="w-6 h-6 fill-current" />
  </ShareButton>
  <ShareButton v-if="available('instagram')" :share="shareOnInstagram">
    <InstagramIcon class="w-6 h-6 fill-current" />
  </ShareButton>
  <ShareButton v-if="available('facebook')" :share="shareOnWhatsapp">
    <WhatsappIcon class="w-6 h-6 fill-current" />
  </ShareButton>
</template>
