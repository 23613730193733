import { api, onLocalStorageChange } from '@dpgradio/creative'
import { defineStore } from 'pinia'

export const useVotesStore = defineStore('votes', {
  state: () => ({
    listId: null,
    editionId: null,
    tracks: null,
    socialMetadata: null,
    winner: null,
  }),
  getters: {
    size() {
      return this.tracks?.length || 0
    },
    localStorageKey() {
      return `top-n-vote-${this.listId}-${this.editionId}`
    },
  },
  actions: {
    async initialize(listId, editionId) {
      this.listId = listId
      this.editionId = editionId

      const { tracks, social } = await api.request().get(`/lists/${listId}/editions/${editionId}/votes/top`)
      this.tracks = tracks
      this.socialMetadata = social

      onLocalStorageChange(
        this.localStorageKey,
        (id) => {
          this.setWinner(parseInt(id))
        },
        true
      )
    },
    async vote(id) {
      await api.request().post(`/lists/${this.listId}/editions/${this.editionId}/votes/top`, {
        votes: [
          {
            id,
            type: 'Nummer 1',
            value: 1,
            comment: '',
          },
        ],
      })

      this.setWinner(id)
    },
    setWinner(id) {
      this.winner = this.tracks.find((track) => track.id === id)
      localStorage.setItem(this.localStorageKey, id)
    },
  },
})
