<script setup>
import { useVotesStore } from './stores/votes'
import Onboarding from './components/Onboarding.vue'
import TrackTiles from './components/TrackTiles.vue'
import Winner from './components/Winner.vue'
import { config } from '@dpgradio/creative'

const votesStore = useVotesStore()
</script>

<template>
  <div class="container mx-auto font-sans relative" :class="config('app.theme')">
    <Onboarding v-if="!votesStore.winner" />
    <Winner />
    <TrackTiles class="p-4 md:p-12" />
  </div>
</template>
